.App {
  background: #f2f4f7;
  .outer-box {
    display: flex;
  }
}
// Assign To modal css
.ant-select-item-option {
  &:hover {
    background: #d4d7db;
  }
  .ant-select-item-option-content {
    .admin-options {
      display: flex;
      align-items: center;
      padding: 9px;
      img {
        border-radius: 50%;
      }
      .admin-details {
        margin-left: 10px;
        p:nth-of-type(1) {
          margin: auto 0;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          color: #343a40;
        }
        p:nth-of-type(2) {
          margin: auto 0;
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 11px;
          line-height: 16px;
          color: #343a40;
          opacity: 0.8;
        }
      }
    }
  }
}
// milestones css
.ant-popover-content {
  .ant-popover-inner {
    .milestones {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
      .sector-boxes {
        width: fit-content;
        background: #f2f2ff;
        border-radius: 50px;
        padding: 1px 13px;
        margin-top: 3px;
        margin-right: 5px;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          color: #162642;
        }
      }
    }
  }
}
