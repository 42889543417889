.transaction-startup-details-container {
  padding: 15px 25px;
  background: #f2f4f7;
  .top-transaction {
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #425a85;
    }
  }
  .startup-details-wrap {
    width: 100%;
    height: auto;
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 15px;
    padding: 24px;
    .ant-spin-spinning {
      position: static;
      display: block;
      opacity: 1;
      padding: 100px;
    }
    .brief-detail {
      padding: 30px 60px;
      .box-wrap {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        .label {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 11px;
          line-height: 16px;
          color: #343a40;
          &.closing-doc-label {
            margin-bottom: 6px;
          }
        }
        .closing-agreements-wrap {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .closing-agreements-link {
            a {
              color: #162642;
              font-family: Roboto;
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              text-decoration-line: underline;
            }
          }
        }
        .dark-value {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #162642;
        }
        .value {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          color: #343a40;
        }
        .pill {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px 12px;
          background: #f2f2ff;
          border-radius: 12px;
          width: max-content;
          text-transform: capitalize;
        }
      }
    }
  }
}
