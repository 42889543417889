.subscribers-container {
  padding: 0px 20px 15px 20px;
  background: #f2f4f7;
  .subscribers-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 6px;
    .left-side {
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 24px;
        color: #425a85;
        margin: auto 0;
      }
    }
    .right-side {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      img {
        margin-right: 9px;
      }
      .selection-menu {
        width: 90px;
        height: 45px;
        border-radius: 8px;
        background: #ffffff;
        color: #162642;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 24px;
        margin-left: 15px;
        .ant-select-selector {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 8px;
          border: none;
          box-shadow: none;
        }
        .ant-select-arrow {
          font-size: 15px;
          color: #162642;
          margin-top: -8px;
        }
      }
      .csv-btn {
        border: none;
        background: transparent;
      }
      .export-data {
        height: 45px;
        color: #162642;
        border: 1px solid #425a85;
        font-size: 15px;
        border-radius: 7px;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-left: 16px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
        img {
          margin-right: 9px;
        }
      }
      .export-btn {
        border: 1px solid #162642;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        color: #162642;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        max-width: 152px;
        width: 100%;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .table-container {
    .ant-table {
      .ant-table-container {
        table thead > tr:first-child th:last-child {
          padding: 10px 25px 10px 10px;
        }
        .ant-table-tbody {
          .ant-table-cell {
            &:last-child {
              padding: 10px 25px 10px 10px;
            }
          }
        }
      }
    }
  }
}

// .ant-table-column-sorters {
//   padding: 16px 10px;
// }
