.video-preview-container {
  padding: 0px 20px 15px 20px;
  background: #f2f4f7;
  .preview-header {
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #425a85;
      margin-bottom: 0;
    }
    .header-right {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        margin-right: 25px;
        cursor: pointer;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          color: #162642;
          margin-left: 6px;
        }
        img {
          height: 15px;
          width: 15px;
        }
      }
    }
  }
  .video-preview-wrap {
    width: 100%;
    height: auto;
    min-height: 600px;
    padding: 48px 40px;
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 15px;
    .left-section {
      .label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        color: #343a40;
        opacity: 0.8;
      }
      .topic {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #343a40;
      }
      .ant-divider {
        border-top-color: #dfdfdf;
      }
      .videos-title-wrap {
        margin-top: 24px;
        padding: 0px 8px 0px 20px;
        .selected-video-title {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #162642;
          text-decoration: underline;
          margin-bottom: 12px;
        }
        .video-title {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 22px;
          color: #343a40;
          margin-bottom: 12px;
          cursor: pointer;
        }
      }
    }
    .content-wrap {
      .label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        color: #343a40;
        opacity: 0.8;
      }
      .summary {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        color: #343a40;
      }
    }
  }
}
.ant-spin-spinning {
  padding: 100px;
}
