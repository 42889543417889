.chapter-preview-container {
  padding: 0px 20px 15px 20px;
  background: #f2f4f7;
  .preview-header {
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #425a85;
      margin-bottom: 0;
    }
    .header-right {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        margin-right: 25px;
        cursor: pointer;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          color: #162642;
          margin-left: 6px;
        }
        img {
          height: 15px;
          width: 15px;
        }
      }
    }
  }
  .chapter-preview-wrap {
    width: 100%;
    height: auto;
    min-height: 600px;
    padding: 48px 40px;
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 15px;
    .header-image-wrap {
      img {
        width: 200px;
        height: 200px;
      }
    }
    .header-default-wrap {
      height: 200px;
      width: 200px;
      border-radius: 10px;
      background-color: #eaecef;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 80px;
        width: 90px;
      }
    }
    .content-wrap {
      h2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 28px;
        line-height: 33px;
        color: #343a40;
      }
      .subtitle {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #343a40;
      }
      .content {
        word-break: break-word;
      }
    }
  }
}
.ant-spin-spinning {
  padding: 100px;
}
