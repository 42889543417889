input:-webkit-autofill{
  -webkit-background-clip: text;
  -webkit-text-fill-color: inherit;
}

.edit-quiz-container {
  padding: 0px 35px 15px 20px;
  background: #f2f4f7;

  .top-edit {
    justify-content: space-between;
    align-items: baseline;
    display: flex;
    margin-bottom: 15px;
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #425a85;
    }
    .edit-right {
      display: flex;
      align-items: center;
      .save-btn {
        height: 45px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #162642;
        background: #ffffff;
        border: 1px solid #425a85;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin: 0 6px;
      }
      .publish-btn {
        height: 45px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        background: #162642;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        border: 1px solid #162642;
        color: #ffffff;
        margin: 0 6px;
      }
    }
  }
  .edit-quiz-wrap {
    width: 100%;
    min-height: 600px;
    height: auto;
    padding: 30px 60px;
    background: #ffffff;
    border-radius: 15px;
    .ant-row {
      margin-bottom: 16px;
    }
    .field-box {
      display: flex;
      flex-direction: column;
      label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        color: #343a40;
        margin-bottom: 8px;
      }
      .inputs-box {
        min-height: 45px;
        background: #ffffff;
        border: 1px solid #ccceff;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 22px;
        color: #343a40;
      }
      .selection-option {
        .ant-select-selector {
          height: 58px;
          background: #ffffff;
          border: 1px solid #ccceff;
          box-sizing: border-box;
          border-radius: 3px;
          .ant-select-selection-item {
            margin: auto 0;
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 22px;
            color: #343a40;
          }
          .ant-select-selection-placeholder {
            margin: auto;
          }
        }
      }
      .ant-select-single:not(.ant-select-customize-input)
        .ant-select-selector
        .ant-select-selection-search-input {
        height: 56px;
      }
      .prefix-icon {
        height: 20px;
        width: 20px;
        margin-right: 6px;
      }
      .textarea-input {
        height: fit-content;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    .timer-wrap {
      display: flex;
      align-items: center;
      padding: 12px 24px;
      border: 1px solid #ccceff;
      box-sizing: border-box;
      border-radius: 5px;
      .clock-wrap {
        height: 32px;
        width: 32px;
        border-radius: 16px;
        justify-content: center;
        align-items: center;
        display: flex;
        background: #eaecef;
        .anticon-clock-circle {
          color: #425a85;
          font-size: 18px;
        }
      }
    }
    .add-que {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px 0px 24px;
      background: #ffffff;
      box-shadow: 0px 1px 12px rgba(46, 37, 37, 0.1);
      margin: 16px 0px;
    }
    .add-que-wrapper {
      background: #eaecef;
      border-radius: 8px;
      width: 100%;
      padding: 16px 24px;
      .label {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #425a85;
      }
      .inputs-box {
        min-height: 45px;
        background: #ffffff;
        border: 1px solid #ccceff;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 22px;
        color: #343a40;
      }
      .create-btn {
        height: 45px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        background: #162642;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        border: 1px solid #162642;
        color: #ffffff;
        margin: 12px 0px 0px;
      }
    }
    .add-question-content {
      margin-top: 8px;
      width: 100%;
      .question {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;
        align-items: center;
        background: #f2f2ff;
        .left-section {
          width: 90%;
          display: flex;
          align-items: center;
          .prefix {
            width: 32px;
            height: 24px;
            align-items: center;
            justify-content: center;
            display: flex;
            background: #162642;
            border-radius: 2px;
            color: white;
            margin-right: 12px;
          }
          .content {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 24px;
            color: #343a40;
          }
        }
        .right-section {
          align-items: center;
          justify-content: space-around;
          display: flex;
          width: 7%;
          .anticon {
            color: #425a85;
            font-size: 22px;
            cursor: pointer;
          }
          img {
            cursor: pointer;
          }
        }
      }
    }
    .options-wrapper {
      width: 92%;
      margin: 0 auto;
      margin-top: 16px;
      .correct {
        display: flex;
        justify-content: flex-end;
        padding: 1px 10px;
      }
      .option-wrap {
        background: #ffffff;
        border: 1px solid #ccceff;
        box-sizing: border-box;
        border-radius: 5px;
        margin: 8px 0px;
        display: flex;
        align-items: center;
        padding: 12px 24px;
        .left-section {
          width: 90%;
          display: flex;
          align-items: center;
          .prefix {
            color: #b1b3b6;
            margin-right: 12px;
            .anticon {
              font-size: 20px;
            }
          }
          input {
            border: none;
            box-shadow: none;
          }
        }
        .right-section {
          margin-left: auto;
          .circle-outer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            border: 1px solid #7c8e54;
            cursor: pointer;
            .circle-inner {
              width: 12px;
              height: 12px;
              border-radius: 6px;
              background: #7c8e54;
              border: 1px solid #7c8e54;
            }
          }
        }
      }
      .text-area-box {
        width: 100%;
        resize: none;
        background: #ffffff;
        border: 1px solid #ccceff;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 22px;
        color: #343a40;
        margin-top: 11px;
      }
    }
    .questions-list-wrap{
      width: 100%;
      .add-question-content {
        margin-top: 8px;
        width: 100%;
        .question {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 16px 24px;
          align-items: center;
          background: #f2f2ff;
          .left-section {
            width: 90%;
            display: flex;
            align-items: center;
            .prefix {
              width: 32px;
              height: 24px;
              align-items: center;
              justify-content: center;
              display: flex;
              background: #162642;
              border-radius: 2px;
              color: white;
              margin-right: 12px;
            }
            .content {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              line-height: 24px;
              color: #343a40;
            }
          }
          .right-section {
            align-items: center;
            justify-content: space-around;
            display: flex;
            width: 10%;
            .anticon {
              color: #425a85;
              font-size: 22px;
              cursor: pointer;
            }
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.ant-popover-content {
  max-width: 600px;
  .ant-popover-inner {
    .ant-popover-title {
      background: #f2f4f7;
    }
    .ant-popover-inner-content {
      background: #f2f4f7;
      border-radius: 0;
      .saved-media-popup {
        max-height: 270px;
        overflow-y: auto;
        width: 100%;
        padding: 8px;
        &::-webkit-scrollbar {
          width: 5px;
          background: #dadada;
          border-radius: 50px;
        }
        &::-webkit-scrollbar-thumb {
          background: #425a85;
          border-radius: 50px;
        }
        .header {
          display: flex;
          align-items: center;
          padding: 14px 7px 0;
          border-bottom: 1px solid #bbbaba;
          h4 {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 24px;
            color: #162642;
          }
        }
        .ant-row:not(:last-child) {
          border-bottom: 0.5px solid #dadada;
          padding: 10px 0;
        }
        .media-name {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 18px;
          color: #162642;
        }
        .media-url {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 18px;
          color: #425a85;
          word-break: break-word;
        }
        .anticon {
          cursor: pointer;
        }
      }
    }
  }
}

.schedule-publish-modal {
  .ant-modal-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: #162642;
  }
  .ant-modal-body {
    .modal-content-detail {
      display: flex;
      flex-direction: column;
      label {
        font-size: 14px;
        line-height: 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        color: #343a40;
      }
      .ant-picker {
        width: 50%;
        height: 45px;
        border-radius: 8px;
        margin-top: 5px;
      }
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    button {
      box-sizing: border-box;

      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 18px 25px;
    }
    .ant-btn:nth-of-type(1) {
      background: #ffffff;
      border: 1px solid #162642;
      color: #162642;
    }
    .ant-btn:nth-of-type(2) {
      background: #162642;
      border: 1px solid #162642;
      color: #ffffff;
    }
  }
}

.ant-spin-spinning {
  display: block;
}
