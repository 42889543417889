input:-webkit-autofill{
  -webkit-background-clip: text;
  -webkit-text-fill-color: inherit;
}
.filter-menu {
  height: 350px;
  overflow-y: scroll;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 24px;
    color: #5e51f4;
  }
  .filter-head {
    padding: 6px 0;
    span {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      color: #767778;
    }
  }
  .sub-status {
    display: flex;
    flex-direction: column;
    .status-title {
      font-family: Roboto;
      margin-left: 0;
      padding: 4px 20px;
    }
  }
  .sub-grade {
    display: flex;
    flex-direction: column;
    .grade-title {
      font-family: Roboto;
      margin-left: 0;
      padding: 4px 20px;
    }
  }
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  cursor: default;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5e51f4;
  border: 1px solid #5e51f4;
}

.ant-checkbox-inner {
  border: 1px solid #cccfff;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #5e51f4;
}

.waitlist-container {
  padding: 0px 20px 15px 20px;
  background: #f2f4f7;

  .waitlist-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 6px;
    .left-side {
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 24px;
        color: #425a85;
        margin: auto 0;
      }
    }
    .right-side {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: auto 0;
      }
      .block-section {
        cursor: not-allowed;
      }
      .startups {
        width: 150px;
        height: 45px;
        font-family: Roboto;
        border: 1px solid #ffffff;
        color: #343a40;
        background: #ffffff;
        border-radius: 5px 0 0 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
      }
      .selected {
        background-color: #162642 !important;
        border: 1px solid #162642 !important;
        color: #ffffff !important;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
      }

      .investors {
        width: 150px;
        height: 45px;
        font-family: Roboto;
        border: 1px solid #ffffff;
        color: #343a40;
        background: #ffffff;
        border-radius: 0 5px 5px 0;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
      }
      .block {
        cursor: not-allowed;
      }
      .ant-dropdown-link {
        width: 100px;
        height: 50px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        color: #425a85;
        margin: 0 15px;
        padding: 0 15px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        cursor: pointer;
        .anticon svg {
          height: 15px !important;
          width: 15px !important;
        }
      }

      .selection-menu {
        width: 90px;
        height: 45px;
        border-radius: 8px;
        background: #ffffff;
        color: #425a85;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 24px;
        .ant-select-selector {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 8px;
          border: none;
          box-shadow: none;
        }
        .ant-select-arrow {
          font-size: 15px;
          color: #425a85;
          margin-top: -8px;
        }
      }
      .bulk-invite {
        width: 100px;
        height: 45px;
        width: 100px;
        border-radius: 8px;
        color: #425a85;
        font-family: Roboto;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        line-height: 24px;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-right: 15px;
        cursor: pointer;
        .bulk-image {
          width: 18px;
          height: 21px;
          margin-left: 6px;
        }
      }
      .invite-history {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        color: #425a85;
        cursor: pointer;
        margin-right: 15px;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 0 24px;
        height: 50px;
        .invite-history-image {
          width: 20px;
          height: 20px;
          margin-left: 12px;
        }
      }
      .invite-group {
        display: flex;
        gap: 6px;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background: #fff;
        padding: 5px;
        .invite-text-box {
          padding: 9px 123px 9px 9px;
          border-radius: 4px;
          border: 1px solid #dedede;
          height: 37px;
          color: #343a40;
          font-size: 13px;
          font-weight: 300;
          line-height: normal;
        }
        .send-icon-wrap {
          width: 37px;
          height: fit-content;
          border-radius: 4px;
          background: #162642;
          padding: 8px;
          margin: 0;
          display: flex;
          align-items: center;
          cursor: pointer;
          .send-icon {
            width: 17px;
            min-width: 17px;
            height: 17px;
          }
        }
      }
    }
  }
  .filter-wrapper {
    height: 100px;
    border-radius: 5px;
    background: #eaecef;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    // padding: 0 12px;
    padding: 0 29px;

    .reset-filters{
      margin-top: 10px;
      margin-right: 20px;
      display: flex;
      color: #425A85;
      padding: 10px 5px;
      justify-content: center;
      background-color: white;
      align-items: center;
      cursor: pointer;
      gap: 5px;
      border-radius: 8px;
      border: 1px #425A85 solid;
      width: fit-content;
    }
    label {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      color: #343a40;
    }
    .ant-dropdown-trigger {
      height: 45px;
      cursor: pointer;
      justify-content: space-between;
      align-items: center;
      display: flex;
      padding: 0 18px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 24px;
      color: #425a85;
      background: #ffffff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      margin-top: 2px;
      .anticon {
        color: #6666ff;
        font-size: 12px;
      }
    }
    .status-class {
      flex: 1;
      margin-right: 24px;
      .ant-dropdown-trigger {
        width: 100%;
      }
    }
    .grade-class {
      flex: 1;
      margin-right: 24px;
      .ant-dropdown-trigger {
        width: 100%;
      }
    }
    .memo-class {
      flex: 1;
      margin-right: 24px;

      .ant-dropdown-trigger {
        width: 100%;
      }
    }
    .our-two-cents-class {
      flex: 1;
      margin-right: 24px;
      .ant-dropdown-trigger {
        width: 100%;
      }
    }
    .invite-code-class {
      flex: 1;
      .ant-dropdown-trigger {
        width: 100%;
      }
    }
    .invest-status {
      margin-right: 24px;
      flex: 1;
      .ant-dropdown-trigger {
        width: 100%;
      }
    }
    .invest-range {
      margin-right: 24px;
      flex: 1;
      .ant-dropdown-trigger {
        width: 100%;
      }
    }
    .invest-prior {
      margin-right: 24px;
      flex: 1;
      .ant-dropdown-trigger {
        width: 100%;
      }
    }
    .invest-invite-code {
      flex: 1;
      .ant-dropdown-trigger {
        width: 100%;
      }
    }
  }
}

.table-container {
  margin: 10px 0;
  .ant-table {
    // height: 550px;
    // overflow-y: scroll;
    // width: 1230px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    .ant-table-container {
      // overflow: auto;
      table {
        // min-width: 1200px;
        thead > tr:first-child th:first-child {
          border-top-left-radius: 10px;
        }
        thead > tr:first-child th:last-child {
          border-top-right-radius: 10px;
        }
      }
    }
    thead {
      tr {
        th {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: #425a85;
          padding: 10px;
        }
      }
    }
    td {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 18px;
      color: #343a40;
      padding: 10px;
    }
    tr:nth-child(even) {
      background: #fff;
      cursor: pointer;
    }
    tr:nth-child(odd) {
      background: #f2f2ff;
      cursor: pointer;
      td {
        background: #f2f2ff;
        transition: none;
      }
    }
  }
}

.ant-pagination-item:hover {
  a {
    color: #162642;
  }
  border-color: #162642;
}

.ant-pagination-item-active:hover a {
  color: #fff;
  background: #162642;
}
.ant-pagination-item-active {
  a {
    color: #fff;
  }
  background: #162642;
  border-color: #162642;
}

.ant-pagination-prev .ant-pagination-item-link:hover,
.ant-pagination-next .ant-pagination-item-link:hover {
  span {
    color: #162642;
  }
  border-color: #162642;
}

.ant-pagination-prev:hover,
.ant-pagination-next:hover {
  border: 1px solid #425a85;
}

.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus-visible {
  border: 1px solid transparent;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  span {
    color: #425a85;
  }
  border-color: #d9d9d9;
  //box-shadow: none;
}

.ant-pagination-disabled:hover .ant-pagination-item-link:hover {
  span {
    color: inherit;
  }
  border-color: #d9d9d9;
}

.ant-pagination-disabled .ant-pagination-item-link {
  span {
    color: inherit;
  }
  border-color: #d9d9d9;
}

.action-dropdown {
  width: 210px;
  background: #ffffff;
  border-radius: 8px;
  li {
    color: #162642;
    padding-left: 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 24px;
    cursor: pointer;
  }
}

.invite-modal {
  background: #ffffff;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50% !important;
  border-radius: 20px;
  .ant-spin {
    color: #162642;
  }
  .ant-spin-spinning {
    position: static;
    display: block;
    opacity: 1;
    margin: 50px;
  }
  .ant-modal-content {
    width: 100%;
    border-radius: 20px;
  }
  .ant-modal-close {
    color: #343a40;
  }
  .ant-modal-body {
    background: #ffffff;
    line-height: 2;
    border-radius: 6px;
    .invite-title {
      // font-size: 22px;
      // font-family: Roboto;
      // color: #5e51f4;
      // margin-bottom: 20px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #162642;
    }
    .file-box {
      width: 100%;
      height: auto;
      background: #eaecef;
      border-radius: 10px;
      padding: 18px;
      margin-top: 6px;
      justify-content: center;
      align-items: center;
      display: flex;
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        color: #162642;
        margin: auto 0;
        text-decoration: underline;
        width: 60%;
      }
      .button-group {
        margin-left: auto;
        .cancel-btn {
          width: 100px;
          height: 45px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          background-color: #ffffff;
          border: 1px solid #425a85;
          color: #425a85;
          border-radius: 7px;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
        }
        .upload-btn {
          width: 100px;
          height: 45px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          background: #162642;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
          border-radius: 5px;
          border: 1px solid #162642;
          color: #ffffff;
          margin-left: 8px;
        }
      }
    }
  }
}

.invite-inst-modal {
  background: #ffffff;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 50% !important;
  border-radius: 20px;

  .ant-modal-content {
    width: 100%;
    border-radius: 20px;
  }
  .ant-modal-close {
    color: #343a40;
  }
  .ant-modal-body {
    background: #ffffff;
    line-height: 2;
    border-radius: 6px;
    .inst-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #162642;
    }
    p {
      padding: 8px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin: 8px 0;
      a {
        color: #162642;
      }
    }

    .upload-btn {
      width: 100px;
      height: 45px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      background: #162642;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      border: 1px solid #162642;
      color: #ffffff;
      margin: 10px auto;
      display: block;
    }
    .ant-upload-list {
      display: none;
    }
  }
}

.Incomplete {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #828282;
}

.Approved {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #162642;
}

.Pending {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #343a40;
}

.Rejected {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #fc4a45;
}

.Tracking {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #343a40;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  background: #fff;
}

.ant-spin-dot-item {
  background: #162642;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background: #162642;
  border-color: #162642;
}

.ant-popover-inner-content {
  padding: 10px !important;
}

.update-position-overlay {
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  width: 160px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .update-position-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #162642;
    margin-bottom: 8px;
  }
  .position-input {
    width: 80px;
    border-radius: 8px;
  }
  .footer-icons-wrap {
    margin-top: 16px;
    .cancel-icon {
      font-size: 26px;
      color: #fc4a45;
      margin-right: 10px;
      cursor: pointer;
    }
    .ok-icon {
      font-size: 26px;
      color: #53a100;
      cursor: pointer;
    }
  }

  .ant-spin-spinning{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
