.navbar-container {
  width: 100%;
  top: 0;
  z-index: 100;
  position: sticky;
  padding: 25px 20px;
  display: flex;
  justify-content: space-between;
  background: #f2f4f7;
  .navbar-left {
    align-items: center;
    display: flex;
    cursor: pointer;
    .back-arrow {
      color: #425a85;
      font-size: 14px;
      margin-right: 7px;
    }
    .ant-input-suffix {
      .anticon {
        height: 100%;
        display: flex;
      }
    }
    .back-title {
      margin: auto 0;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 24px;
      color: #425a85;
    }
    .v-line {
      height: 28px;
      border-left: 2px solid #425a85;
      margin-bottom: 0px;
    }
    .search-input {
      height: 28px;
      width: 400px;
      margin-left: 20px;
      border: 0;
      background: transparent;
      input {
        font-weight: 300;
        font-size: 13px;
        line-height: 24px;

        background: transparent !important;
        &::placeholder {
          color: #425a8580;
        }
      }

      .anticon svg {
        display: inline-block;
        font-size: 19px;
        opacity: 0.5;
        color: #425a85;
        margin-right: 10px;
      }
    }
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper-focused {
      box-shadow: none;
    }
  }
  .navbar-right {
    display: flex;
    align-items: center;
    margin-right: 25px;

    .bell-icon {
      height: 28px;
      width: 28px;
      cursor: pointer;
    }
    .profile-icon {
      padding-left: 45px;
      cursor: pointer;
    }
  }
}

.menu {
  width: 230px;
  align-items: flex-start;
  border-radius: 5px;
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    border-bottom: 1px solid #6666ff20;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }
  .menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 3px;
    cursor: pointer;
    .item-icon {
      color: #425a85;
      font-size: 18px;
    }
    span {
      padding-left: 12px;
    }
    .menu-item-title {
      flex-grow: 0;
      font-family: Roboto;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: 1px;
      text-align: left;
      color: #425a85;
    }
  }
}

.ant-popover-inner {
  border-radius: 10px;
  .ant-popover-inner-content {
    padding: 0;
    background: #ffffff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    .notifications-content-wrapper {
      width: 430px;
      .ant-spin-spinning {
        padding: 50px;
      }
      .pop-header {
        display: flex;
        padding: 18px 24px;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          color: #425a85;
          margin-bottom: 0;
          span {
            opacity: 0.5;
            margin-left: 6px;
          }
        }
        img {
          opacity: 0.5;
        }
      }
      .pop-content {
        border-top: 0.7px solid #dadada;
        border-bottom: 0.7px solid #dadada;
        max-height: 330px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 8px;
          background: #dadada;
          border-radius: 50px;
        }
        &::-webkit-scrollbar-thumb {
          background: #425a85;
          border-radius: 50px;
        }
        .line-hr {
          border-bottom: 0.7px solid #eee;
          padding: 8px 24px;
          .alignments {
            display: flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            width: 100%;
          }
          .img-wrapper {
            width: 40px;
            height: 40px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          h6 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 22px;
            color: #343a40;
            margin-bottom: 0;
          }
          .notification-msg {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: #343a40;
            opacity: 0.8;
            word-break: break-word;
          }
          .timing {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: #425a85;
          }
        }
        .line-hr:last-child {
          border-bottom: none;
        }
      }
      .pop-footer {
        padding: 11px 0 13px 0;
        text-align: center;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          color: #425a85;
          cursor: pointer;
        }
      }
    }
  }
}

.ant-popover-inner-content {
  background: #fff !important;
  border-radius: 10px;
  .search-drop {
    width: 100%;
    padding: 10px;
    .search-tag {
      background: #f2f2ff;
      border-radius: 50px;
      padding: 10px;
      cursor: pointer;
    }
  }
}
