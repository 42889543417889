input:-webkit-autofill{
  -webkit-background-clip: text;
  -webkit-text-fill-color: inherit;
}
.edit-blog-container {
  padding: 0px 35px 15px 20px;
  background: #f2f4f7;

  .top-add {
    justify-content: space-between;
    align-items: baseline;
    display: flex;
    margin-bottom: 15px;
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #425a85;
    }
    .add-right {
      display: flex;
      align-items: center;
      .save-btn {
        height: 45px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #162642;
        background: #ffffff;
        border: 1px solid #425a85;
        box-sizing: border-box;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin: 0 6px;
      }
      .publish-btn {
        height: 45px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        background: #162642;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        border: 1px solid #162642;
        color: #ffffff;
        margin: 0 6px;
      }
    }
  }
  .edit-blog-wrap {
    width: 100%;
    height: auto;
    padding: 30px 40px;
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 15px;
    .ant-row {
      margin-bottom: 24px;
    }
    .tox {
      width: 100%;
      .tox-statusbar {
        display: none;
      }
    }
    .profile-icon {
      margin: 10px auto;
      position: relative;
      .camera-icon-wrap {
        border: 1px solid #eee;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #edeef0;
        position: absolute;
        bottom: 0;
        left: 70px;
        cursor: pointer;
      }
      .ant-upload-list {
        display: none;
      }
    }
    .upload-profile-btn {
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      background: #162642;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      border: 1px solid #162642;
      color: #ffffff;
      margin-left: 12px;
    }
    .upload-banner {
      width: 100%;
      height: 130px;
      background: #f2f2ff;
      border: 1px dashed #ccceff;
      box-sizing: border-box;
      border-radius: 3px;
      .ant-upload-list {
        display: none;
      }
      .ant-upload.ant-upload-select {
        width: 100%;
        height: 100%;

        cursor: pointer;
      }
      .upload-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 21px;
          line-height: 18px;
          color: #ccceff;
        }
      }
      .banner-image-wrap {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        text-align: center;
        &::-webkit-scrollbar {
          width: 8px;
          background: #dadada;
          border-radius: 50px;
        }
        &::-webkit-scrollbar-thumb {
          background: #425a85;
          border-radius: 50px;
        }
        img {
          max-width: 100%;
        }
      }
    }
    .desc {
      font-size: 14px;
      line-height: 23px;
      margin-top: 14px;
      text-align: center;
      color: #bc9e00;
      display: flex;
      align-items: center;
      width: 345px;
    }
    .upload-banner-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      background: #162642;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      border: 1px solid #162642;
      color: #ffffff;
      margin-top: 12px;
      margin-left: auto;
      padding: 12px;
    }
    .field-box {
      display: flex;
      flex-direction: column;
      label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 16px;
        color: #343a40;
        margin-bottom: 8px;
      }
      .inputs-box {
        min-height: 45px;
        background: #ffffff;
        border: 1px solid #ccceff;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
        line-height: 22px;
        color: #343a40;
        position: relative;
        .podcast-input {
          position: absolute;
          right: 5px;
          bottom: 2px;
          color: rgba(0, 0, 0, 0.45);
          margin: 0;
          letter-spacing: 1px;
        }
      }
      .selection-option {
        .ant-select-selector {
          background: #ffffff;
          border: 1px solid #ccceff;
          box-sizing: border-box;
          border-radius: 3px;
          box-shadow: none;
          .ant-select-selection-item {
            margin: auto 0;
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 22px;
            color: #343a40;
          }
        }
      }
    }
    .upload-btn {
      margin-left: auto;
      display: block;
      height: 45px;
      background: #ffffff;
      border: 1px solid #425a85;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      color: #162642;
      img {
        width: 14px;
        height: 14px;
        margin-right: 11px;
      }
    }
  }
}
.tox-notifications-container {
  display: none;
}
.upload-media-modal {
  .ant-modal-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: #162642;
  }
  .ant-modal-body {
    .media-name {
      label {
        font-size: 14px;
        line-height: 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        color: #343a40;
      }
      input {
        font-size: 14px;
        line-height: 22px;
        background: #ffffff;
        border: 1px solid #ccceff;
        box-sizing: border-box;
        border-radius: 3px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        color: #343a40;
        min-height: 40px;
        margin-top: 6px;
      }
    }
    .ant-upload-list {
      display: none;
    }
    .select-media-btn {
      background: #ffffff;
      border: 1px solid #162642;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      color: #162642;
      margin-top: 12px;
      display: flex;
      align-items: center;
      padding: 18px 25px;
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    button {
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 18px 25px;
    }
    .ant-btn:nth-of-type(1) {
      background: #ffffff;
      border: 1px solid #162642;
      color: #162642;
    }
    .ant-btn:nth-of-type(2) {
      background: #162642;
      border: 1px solid #162642;
      color: #ffffff;
    }
  }
  .filename {
    margin-top: 14px;
    display: block;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #162642;
    .anticon {
      color: #fc4a45;
      margin-left: 6px;
      cursor: pointer;
    }
  }
}
.ant-popover-content {
  max-width: 600px;
  .ant-popover-inner {
    .ant-popover-title {
      background: #f2f4f7;
    }
    .ant-popover-inner-content {
      background: #f2f4f7;
      border-radius: 0;
      .saved-media-popup {
        max-height: 270px;
        overflow-y: scroll;
        width: 100%;
        padding: 8px;
        &::-webkit-scrollbar {
          width: 5px;
          background: #dadada;
          border-radius: 50px;
        }
        &::-webkit-scrollbar-thumb {
          background: #425a85;
          border-radius: 50px;
        }
        .header {
          display: flex;
          align-items: center;
          padding: 14px 7px 0;
          border-bottom: 1px solid #bbbaba;
          h4 {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 21px;
            line-height: 24px;
            color: #162642;
          }
        }
        .ant-row:not(:last-child) {
          border-bottom: 0.5px solid #dadada;
          padding: 10px 0;
        }
        .media-name {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 18px;
          color: #162642;
        }
        .media-url {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 18px;
          color: #425a85;
          word-break: break-word;
        }
        .anticon {
          cursor: pointer;
        }
      }
    }
  }
}

.schedule-publish-modal {
  .ant-modal-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    color: #162642;
  }
  .ant-modal-body {
    .modal-content-detail {
      display: flex;
      flex-direction: column;
      label {
        font-size: 14px;
        line-height: 16px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        color: #343a40;
      }
      .ant-picker {
        width: 50%;
        height: 45px;
        border-radius: 8px;
        margin-top: 5px;
      }
    }
  }
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    button {
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      text-align: center;
      display: flex;
      align-items: center;
      padding: 18px 25px;
    }
    .ant-btn:nth-of-type(1) {
      background: #ffffff;
      border: 1px solid #162642;
      color: #162642;
    }
    .ant-btn:nth-of-type(2) {
      background: #162642;
      border: 1px solid #162642;
      color: #ffffff;
    }
  }
}

.ant-spin-spinning {
  display: block;
}
