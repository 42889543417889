.profile-container {
  padding: 20px 15px;
  .profile-wrapper {
    height: auto;
    width: 100%;
    border: 1px solid #6666ff50;
    border-radius: 8px;
    padding: 15px 30px;
    .edit-profile-icon {
      font-size: 20px;
      color: #162642;
      text-align: right;
    }
    .ant-spin-spinning {
      margin: 0 auto;
      display: block;
      .ant-spin-dot-item {
        background: #162642;
      }
    }
    .profile-icon {
      margin: 10px auto;
      text-align: center;
      align-items: center;
      // left: 39%;
      // position: absolute;
      position: relative;
      .camera-icon-wrap {
        text-align: center;
        border: 1px solid #eee;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        align-items: center;
        background: #edeef0;
        position: absolute;
        bottom: 0;
        right: 32%;
        cursor: pointer;
      }
      .ant-upload-list {
        display: none;
      }
    }
    .update-profile {
      margin: 20px auto 0;
      display: block;
      height: 40px;
      background: #162642;
      color: #ffffff;
      border: 1px solid #162642;
      border-radius: 5px;
      font-size: 16px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    }
    .name-and-role {
      // margin: 14px auto 25px;
      margin: 18px auto 36px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .user-name {
        font-family: Roboto;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #162642;
      }
      .user-role {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: center;
        color: #343a40;
      }
    }
    .pos-and-invite {
      display: flex;
      .user-pos {
        .user-pos-key {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: normal;
          text-align: left;
          color: #475058;
        }
        .user-pos-value {
          // padding: 5px 15px;
          padding: 3px 15px;
          border-radius: 20px;
          background: #f2f2ff;
          font-family: Roboto;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.69;
          letter-spacing: normal;
          text-align: left;
          color: #162642;
          margin-top: -10px;
          text-transform: capitalize;
        }
      }
      .user-invite {
        padding-left: 80px;
        .user-pos-key {
          font-family: Roboto;
          font-size: 14px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: normal;
          text-align: left;
          color: #475058;
        }
        .user-pos-value {
          // padding: 5px 15px;
          padding: 3px 12px;
          border-radius: 20px;
          background: #f2f2ff;
          font-family: Roboto;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.69;
          letter-spacing: normal;
          text-align: left;
          color: #162642;
          margin-top: -10px;
        }
      }
    }
    .email-wrap {
      display: flex;
      flex-direction: column;
      .email-title {
        font-family: Roboto;
        // font-size: 12px;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: left;
        color: #475058;
        margin-top: 10px;
      }
      .bioInput {
        //height: 35px;
        margin: 0 0 7px 0;
        border: 1px solid grey;
        font-size: 14px;
        &:focus {
          box-shadow: none;
        }
      }
      .ant-btn {
        height: 40px;
        background: #162642;
        color: #ffffff;
        border: 1px solid #162642;
        border-radius: 5px;
        font-size: 16px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
        margin: 5px auto;
        display: block;
      }
      .email-value {
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: left;
        color: #343a40;
        word-break: break-word;
      }
    }
    .interests {
      margin: 8px auto;
      display: flex;
      flex-direction: column;
      .label {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: left;
        color: #475058;
        margin: 8px 0 6px;
      }
      .highlight-group {
        display: flex;
        flex-wrap: wrap;
        .empty-value {
          font-family: Roboto;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.69;
          letter-spacing: normal;
          text-align: left;
          color: #425a85;
          margin: 1px 5px;
        }
        .highlight {
          padding: 2px 10px;
          border-radius: 20px;
          background: #f2f2ff;
          font-family: Roboto;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.69;
          letter-spacing: normal;
          color: #425a85;
          margin: 1px 4px 0 0px;
          text-transform: capitalize;
        }
      }
      .add-location {
        display: flex;
        justify-content: space-between;
        button {
          background: transparent;
          border: 1px solid #162642;
          color: #162542;
          border-radius: 4px;
          font-size: 13px;
          &:focus {
            outline: none;
            border: 1px solid #162642;
          }
        }
      }
      .locations {
        margin: 10px 0px;
        display: flex;
        flex-wrap: wrap;
        .ant-tag {
          padding: 4px 20px 4px 4px;
          margin: 4px;
          position: relative;
          white-space: pre-line;
          .anticon {
            position: absolute;
            top: 0;
            right: 5px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
