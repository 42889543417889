.learner-details-container {
  padding: 15px 25px;
  background: #f2f4f7;
  .top-learner {
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #425a85;
    }
    .learner-right {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 16px;
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        text-align: center;
        color: #162642;
        margin: auto 0 auto 25px;
        img {
          width: 15px;
          height: 15px;
          margin-right: 6px;
        }
        .right-arrow {
          margin-left: 6px;
        }
      }
    }
  }
  .learner-details-wrap {
    width: 100%;
    height: auto;
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 15px;
    .ant-spin-spinning {
      position: static;
      display: block;
      opacity: 1;
      padding: 100px;
    }
    .signup-status {
      width: 100%;
      padding: 40px 0px 20px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .sign-up {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        .right-label {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          color: #343a40;
        }
        .left-label {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 11px;
          line-height: 16px;
          color: #343a40;
          margin-bottom: 2px;
          opacity: 0.8;
        }
      }
      .logo-wrap {
        width: 109px;
        height: 109px;
        margin: 0 auto 22px auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
    }
    .brief-detail {
      padding: 40px 40px 20px 40px;
      .cname-grade-status {
        display: flex;
        flex-direction: row;
        margin-bottom: 12px;
        .ant-row {
          width: 100%;
        }
        h6 {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 24px;
          line-height: 24px;
          color: #162642;
        }
      }
      .right-boxes {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 24px;
        .label {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 11px;
          line-height: 16px;
          color: #343a40;
          opacity: 0.8;
        }
        .right-text-bottom {
          font-family: Roboto;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          color: #343a40;
          small {
            background: #f2f2ff;
            border-radius: 50px;
            margin-left: 20px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 24px;
            color: #162642;
            padding: 4px 13px;
          }
        }
      }
      .invested {
        margin-bottom: 28px;
        .startup-invested {
          .title {
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 11px;
            line-height: 16px;
            opacity: 0.8;
            color: #343a40;
            span {
              margin-left: 8px;
              font-family: Roboto;
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 24px;
              color: #343a40;
            }
          }
          .startup-details {
            display: flex;
            flex-direction: column;
            //align-items: center;
            margin-top: 8px;
            .startup-name {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 13px;
              font-weight: 300;
              line-height: 16px;
              color: #343a40;
            }
            .status {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              color: #343a40;
              line-height: 24px;
              opacity: 0.9;
            }
          }
          .grade-detail-wrap {
            display: flex;
            padding: 0 8px;
            align-items: center;
            justify-content: space-between;
            margin-top: 8px;
            .title {
              font-family: Roboto;
              font-style: normal;
              font-weight: 500;
              font-size: 13px;
              line-height: 18px;
              color: #343a40;
            }
            .grade-wrap {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 2px 12px;
              background: #162642;
              border-radius: 50px;
              .label{
                font-family: Roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 11px;
                line-height: 16px;
                color: #FFFFFF;
                margin-right: 6px;
              }
              .value{
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                line-height: 22px;
                color: #FFFFFF;
              }
            }
          }
        }
      }
    }
  }
}
