.videos-container {
  padding: 0px 20px 15px 20px;
  background: #f2f4f7;
  .videos-header {
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #425a85;
      margin-bottom: 0;
    }
    .header-right {
      display: flex;
      align-items: center;
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        box-shadow: none;
        border-color: #dadada;
      }
      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: #dadada;
      }
      .ant-select {
        margin-right: 20px;
        .ant-select-selector {
          width: 210px;
          height: 45px;
          background: #ffffff;
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
          border-radius: 8px;
          display: flex;
          align-items: center;
          border: none;
          .ant-select-selection-item {
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 15px;
            line-height: 24px;
            color: #425a85;
          }
        }
        .ant-select-arrow {
          color: #425a85;
        }
      }
      p {
        margin-bottom: 0;
        margin-right: 25px;
        cursor: pointer;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          color: #162642;
          margin-left: 6px;
        }
      }
      img {
        cursor: pointer;
      }
      .selection-menu {
        width: 90px;
        height: 45px;
        border-radius: 8px;
        background: #ffffff;
        color: #162642;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 24px;
        margin-left: 15px;
        .ant-select-selector {
          width: 90px;
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 8px;
          border: none;
          box-shadow: none;
        }
        .ant-select-arrow {
          font-size: 15px;
          color: #162642;
          margin-top: -8px;
        }
      }
    }
  }
  .video-list-groups {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    max-height: 550px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
      background: #dadada;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-thumb {
      background: #425a85;
      border-radius: 50px;
    }
    .single-list {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 11px 20px;
      cursor: pointer;

      .icon-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #eaecef;
        border-radius: 20px;
      }
      .title {
        h6 {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #343a40;
          margin-bottom: 0;
          .draftBadge {
            margin: 0 10px;
            max-height: 22px;
            padding: 5px 12px;
            border-radius: 50px;
            background-color: #425a851a;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #425a85;
          }
          .publishBadge {
            text-transform: none;
            margin: 0 10px;
            max-height: 24px;
            padding: 5px 12px;
            border-radius: 50px;
            background-color: #6fcf9740;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            color: #219653;
            display: inline-flex;
            gap: 6px;
            align-items: center;
          }
        }
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 24px;
          color: #343a40;
        }
      }
      .author-details {
        span {
          display: flex;
          align-items: center;
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          line-height: 28px;
          color: #7d8185;
          img {
            padding-left: 12px;
            padding-right: 4px;
          }
          img:first-child {
            padding-left: 0px;
          }
        }
      }
      .icon-group {
        img {
          cursor: pointer;
        }
        img:not(:nth-child(1)) {
          margin-left: 22px;
        }
      }
    }
    .single-list:nth-child(even) {
      background: #f2f2ff;
    }
  }
  .pagination {
    margin-top: 20px;
    .ant-pagination {
      display: flex;
    }
  }
}
.ant-spin-spinning {
  padding: 100px;
}
