.coInv-section {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  .logo-uploader {
    width: 72px;
    height: 56px;
    border-radius: 4px;
    // padding: 3px;
    border: 1px dashed #ccceff;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .logo-img{
      width: 71px;
      height: 55px;
      object-fit: contain;
    }
    .upload-icon{
      padding: 3px 0px;
      p {
        font-family: Roboto;
        font-size: 13px;
        font-weight: 300;
        line-height: 22px;
        text-align: center;
        color: #343a40 !important;
      }
    }
  }
  .inputs-box {
    height: auto;
    background: #ffffff;
    border: 1px solid #ccceff;
    box-sizing: border-box;
    border-radius: 3px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 22px;
    color: #343a40;
  }
  .trash-icon {
    width: 36px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f8f9;
    border-radius: 50px;
    margin-top: 12px;
  }
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 160px;
  height: 40px;
  padding: 8px 20px;
  border-radius: 5px;
  border: 1px solid #162642;
  color: #162642;
  gap: 10px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    border: 1px solid #162642;
    color: #162642;
  }
  &:focus {
    border: 1px solid #162642;
    color: #162642;
  }
}

.ant-upload-list-item-info{
  display: none;
}