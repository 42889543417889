.quiz-preview-container {
  padding: 0px 20px 15px 20px;
  background: #f2f4f7;
  .preview-header {
    padding-right: 10px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-bottom: 12px;
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #425a85;
      margin-bottom: 0;
    }
    .header-right {
      display: flex;
      align-items: center;
      p {
        margin-bottom: 0;
        margin-right: 25px;
        cursor: pointer;
        span {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          color: #162642;
          margin-left: 6px;
        }
        img {
          height: 15px;
          width: 15px;
        }
      }
    }
  }
  .quiz-preview-wrap {
    width: 100%;
    height: auto;
    min-height: 600px;
    padding: 48px 40px;
    background: #ffffff;
    margin-bottom: 20px;
    border-radius: 15px;
    .left-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      h4 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #242c2e;
        margin: 12 auto;
      }
      .time-details {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 160px;
        width: 160px;
        border-radius: 50%;
        border: 5px solid #425a85;
        .time-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          div {
            margin-left: 4px;
            .value {
              color: #7c8e54;
              font-family: Roboto;
              font-style: normal;
              font-weight: bold;
              font-size: 28px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .content-wrap {
      padding: 12px 50px !important;
      .question-wrap {
        margin-top: 24px;
        .question-content {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 16px;
          margin-bottom: 24px;
          .question {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 24px;
            text-align: center;
            color: #162642;
          }
        }
        .options {
          border-bottom: none;
          .option {
            background: #f8f8f9;
            border: 1px solid #eaecef;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 12px 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            span {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
            }
          }
          .option:hover{
            background: #eaecef;
          }
          .correct-option,.correct-option:hover{
            background: #7c8e54;
            span{
              color: #eaecef;
            }
          }
          .incorrect-option,.incorrect-option:hover{
            background: #FC4A45;
            span{
              color: #eaecef;
            }
          }
          .ans-message{
            margin-top: 4px;
            padding: 0 8px;
            .wrong-icon{
              color: #FC4A45;
            }
            .wrong{
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 19px;
              color: #FC4A45;
              margin: 0 4px;
            }
            .right-icon{
              color: #7c8e54;
            }
            .right{
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 19px;
              color: #7c8e54;
              margin: 0 4px;
            }
          }
        }
        .wrong-ans-wrap {
          margin-top: 16px;
          .label {
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 16px;
            color: #343a40;
            opacity: 0.8;
          }
          .expl-content {
            border: 1px solid #eaecef;
            padding: 12px 8px;
            margin-top: 8px;
            span {
              font-family: Roboto;
              font-style: normal;
              font-size: 13px;
              line-height: 24px;
              text-align: center;
              color: #425a85;
            }
          }
        }
        .pre-next-wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 16px;
          .prev-que {
            cursor: pointer;
            align-items: center;
            margin-right: auto;
            .title {
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 19px;
              color: #5d6166;
              margin: 0 8px;
            }
          }
          .next-que{
            margin-right: 0;
            margin-left: auto;
          }
        }
      }
    }
  }
}
.ant-spin-spinning {
  padding: 100px;
}
