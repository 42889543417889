.sidebar-container {
  height: 100vh;
  width: 240px;
  position: sticky;
  left: 0;
  top: 0;
  padding: 10px 25px;
  .sidebar-header {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    margin-top: 10px;
  }
  .items-container {
    .item-wrapper {
      display: flex;
      height: 40px;
      width: 100%;
      margin-top: 10px;
      border-radius: 4px;
      align-items: center;
      padding-left: 16px;
      cursor: pointer;
      .item-icon {
        font-size: 20px;
        color: grey;
      }
      .item-title {
        font-size: 18px;
        font-family: Roboto;
        font-weight: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #425a85;
        padding-left: 20px;
      }
    }
    .selected {
      border-radius: 4px;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
      background-color: #162642;
      .item-icon {
        color: #fff;
      }
      .item-title {
        color: #fff;
      }
    }
  }
}
