input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: inherit;
}

.add-portfolio-container {
  padding: 15px 35px 15px 25px;
  background: #f2f4f7;

  .top-portfolio {
    justify-content: space-between;
    align-items: baseline;
    display: flex;
    margin-bottom: 15px;
    h6 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 24px;
      color: #425a85;
    }
    .portfolio-right {
      display: flex;
      align-items: center;
      .save-btn {
        width: 100px;
        height: 45px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        background: #162642;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        border: 1px solid #162642;
        color: #ffffff;
      }
    }
  }

  .startup-edit-wrap {
    width: 100%;
    height: auto;
    // padding: 30px 40px;
    background: #ffffff;
    margin-bottom: 20px;
    padding: 32px 46px;
    border-radius: 15px;
    .inner-section {
      max-width: 620px;
      .banner-text {
        font-weight: 300;
        font-size: 11px;
        line-height: 16px;
        color: #343a40;
      }
      .logo {
        max-width: 175px;
        width: 100%;
        height: 100px;
        background: #ffffff;
        border: 1px solid #dadada;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 18px 23px 17px;
        position: relative;
        margin-bottom: 18px;
        .logo-img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
        .edit-logo {
          position: absolute;
          bottom: -10px;
          right: 14px;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #edeef0;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .ant-upload-list {
          display: none;
        }
      }
      .field-box, .description-box {
        margin-bottom: 20px;
        label {
          font-family: Roboto;
          font-size: 11px;
          font-weight: 300;
          line-height: 16px;
          color: #343a40;
        }
        .inputs-box {
            height: 45px;
            background: #ffffff;
            border: 1px solid #ccceff;
            box-sizing: border-box;
            border-radius: 3px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 22px;
            color: #343a40;
          }
          .text-area-box {
            min-height: 45px;
            resize: none;
            position: relative;
            background: #ffffff;
            border: 1px solid #ccceff;
            box-sizing: border-box;
            border-radius: 3px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 22px;
            color: #343a40;
            margin-top: 11px;
            word-break: break-word;
            &:after {
              position: absolute;
              bottom: 0;
              right: 5px;
              z-index: 9;
            }
          }
      }
    }
    .inner-section2 {
      max-width: 620px;
      margin-top: 20px;
      h2 {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        color: #343a40;
        margin-bottom: 20px;
      }
    }
  }
}

.validation-msg {
  color: red;
  font-size: 12px;
}