.rejected-container {
  padding: 0px 20px 15px 20px;
  background: #f2f4f7;
  .rejected-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    margin-bottom: 6px;
    .left-side {
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 24px;
        color: #425a85;
        margin: auto 0;
      }
    }
    .right-side {
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: auto 0;
      }
      .startups {
        width: 150px;
        height: 45px;
        font-family: Roboto;
        border: 1px solid #ffffff;
        color: #162642;
        background: #ffffff;
        border-radius: 5px 0 0 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
      }
      .selected {
        background-color: #162642 !important;
        border: 1px solid #162642 !important;
        color: #ffffff !important;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
      }
      .investors {
        width: 150px;
        height: 45px;
        font-family: Roboto;
        border: 1px solid #ffffff;
        color: #162642;
        background: #ffffff;
        border-radius: 0 5px 5px 0;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 24px;
      }

      .block {
        cursor: not-allowed;
      }
      .selection-menu {
        width: 90px;
        height: 45px;
        border-radius: 8px;
        background: #ffffff;
        color: #162642;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
        font-family: Roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
        line-height: 24px;
        margin-left: 15px;
        .ant-select-selector {
          height: 100%;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 8px;
          border: none;
          box-shadow: none;
        }
        .ant-select-arrow {
          font-size: 15px;
          color: #162642;
          margin-top: -8px;
        }
      }
    }
  }
}

.ant-table-column-sorters {
  padding: 16px 10px;
}
